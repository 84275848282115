import { asArray, isset } from "@cawita/core-front";
import { InterventionFlow, Mission, MissionActionType, MissionCustomerStatus, MissionProStatus, WidgetViewMode, WidgetViewModeInput } from "../../models";

export const ProMissionActionMap: Record<MissionActionType, (mission: Mission) => boolean> = {
    accept: m => !isset(m),
    refuse: m => !isset(m),
    cancel: m => isset(m) && checkMissionStatus(m, { proStatus: MissionProStatus.Accepted, customerStatus: [MissionCustomerStatus.Accepted, MissionCustomerStatus.Finished] }),
    finish: m => isset(m) && (
        checkMissionStatus(m, { proStatus: MissionProStatus.Accepted, customerStatus: [MissionCustomerStatus.Accepted, MissionCustomerStatus.Finished] }) ||
        checkMissionStatus(m, { proStatus: MissionProStatus.Finished, customerStatus: [MissionCustomerStatus.Accepted] })
    ),
}

export const CustomerMissionActionMap: Record<MissionActionType, (mission: Mission) => boolean> = {
    accept: m => isset(m) && checkMissionStatus(m, { customerStatus: MissionCustomerStatus.Pending, proStatus: MissionProStatus.Accepted }),
    refuse: m => isset(m) && checkMissionStatus(m, { customerStatus: MissionCustomerStatus.Pending, proStatus: MissionProStatus.Accepted }),
    cancel: m => isset(m) && checkMissionStatus(m, { customerStatus: MissionCustomerStatus.Accepted, proStatus: [MissionProStatus.Accepted, MissionProStatus.Finished] }),
    finish: m => isset(m) && (
        checkMissionStatus(m, { customerStatus: MissionCustomerStatus.Accepted, proStatus: [MissionProStatus.Accepted, MissionProStatus.Finished] }) ||
        checkMissionStatus(m, { customerStatus: MissionCustomerStatus.Finished, proStatus: [MissionProStatus.Accepted] })
    ),
}

export function getMainMissionForCustomer(missions?: Mission[], flow?: InterventionFlow) {
    if (!missions?.length) return null;

    if (flow === InterventionFlow.Direct) return missions[0];

    const positive = _getPositiveMission(missions);
    const neutral = _getNeutralMission(missions);
    const negative = _getNegativeMission(missions);

    if (positive) return positive;
    if (neutral) return neutral;
    if (negative) return negative;
    return null;

}

function _getPositiveMission(missions: Mission[]) {
    return (
        getMissionForStatuses(missions, MissionCustomerStatus.Finished, MissionProStatus.Finished) ??
        getMissionForStatuses(missions, MissionCustomerStatus.Finished, MissionProStatus.Accepted) ??
        getMissionForStatuses(missions, MissionCustomerStatus.Accepted, MissionProStatus.Finished) ??
        getMissionForStatuses(missions, MissionCustomerStatus.Accepted, MissionProStatus.Accepted)
    )
}

function _getNeutralMission(missions: Mission[]) {
    let m: Mission[] = getMissionsForStatuses(missions, MissionCustomerStatus.Pending, MissionProStatus.Accepted);
    if (m.length === 0) return null;
    if (m.length > 1) return null;
    return m[0];
}

function _getNegativeMission(missions: Mission[]) {
    return (
        getMissionForStatuses(missions, MissionCustomerStatus.Canceled, MissionProStatus.Canceled) ??
        getMissionForStatuses(missions, MissionCustomerStatus.Canceled, MissionProStatus.Accepted) ??
        getMissionForStatuses(missions, MissionCustomerStatus.Accepted, MissionProStatus.Canceled)
    )
}

export function shouldShowOtherMissions(missions: Mission[]) {
    const main = getMainMissionForCustomer(missions);
    return !isset(main);
}

export function getMissionForStatuses(missions: Mission[], cStatus: MissionCustomerStatus, pStatus: MissionProStatus): Mission {
    return missions.find(m => m.proStatus === pStatus && m.customerStatus === cStatus);
}

export function getMissionsForStatuses(missions: Mission[], cStatus: MissionCustomerStatus, pStatus: MissionProStatus): Mission[] {
    return missions.filter(m => m.proStatus === pStatus && m.customerStatus === cStatus);
}

export function checkMissionStatus(mission: Mission, options: {
    customerStatus?: MissionCustomerStatus | MissionCustomerStatus[],
    proStatus?: MissionProStatus | MissionProStatus[]
}) {
    const matchesCustomerStatus = isset(mission) && (!isset(options?.customerStatus) || asArray(options.customerStatus).includes(mission.customerStatus));
    const matchesProStatus = isset(mission) && (!isset(options?.proStatus) || asArray(options.proStatus).includes(mission.proStatus));
    return matchesCustomerStatus && matchesProStatus;
}

export function canExecuteAction(view: WidgetViewModeInput, action: MissionActionType, mission: Mission) {
    if (view === WidgetViewMode.Customer) return canExecuteActionAsCustomer(action, mission);
    else if (view === WidgetViewMode.Pro) return canExecuteActionAsPro(action, mission);
    return false;
}

export function canExecuteActionAsPro(action: MissionActionType, mission: Mission) {
    if (!isset(action)) return false;
    if (!isset(ProMissionActionMap[action])) return false;
    return ProMissionActionMap[action](mission);
}

export function canExecuteActionAsCustomer(action: MissionActionType, mission: Mission) {
    if (!isset(action)) return false;
    if (!isset(CustomerMissionActionMap[action])) return false;
    return CustomerMissionActionMap[action](mission);
}