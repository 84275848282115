export enum WidgetViewMode {
    Pro = 'pro',
    Customer = 'customer'
}

export type WidgetViewModeInput = WidgetViewMode | 'pro' | 'customer';

// @index(['./!(*.spec).ts', './*/index.ts'], f => `export * from '${f.path.replace(/\/index$/, '')}';`)
export * from './address.model';
export * from './category.model';
export * from './customer-address.model';
export * from './customer-rate.model';
export * from './customer.model';
export * from './intervention.model';
export * from './job.model';
export * from './localized-address.model';
export * from './market';
export * from './media.model';
export * from './mission.model';
export * from './notification-check.model';
export * from './notification.model';
export * from './pro-config.model';
export * from './pro-rate.model';
export * from './pro.model';
export * from './pro-request.model';
export * from './rating.model';
export * from './role.model';
export * from './setting.model';
export * from './showroom.model';
export * from './skill.model';
export * from './slot.model';
export * from './stats';
export * from './template.model';
export * from './upload-file.model';
export * from './user-lock.model';
export * from './user-session.model';
export * from './user.model';
// @endindex