import { Directive, Inject, Input, OnChanges, OnDestroy, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { Notification, WidgetViewMode, WidgetViewModeInput } from '../../../../../frontend-shared/src/lib/models';
import { SimpleNotificationContract, SimpleNotificationService } from '../../../../../frontend-shared/src/lib/services/apis';
import { CwtDirectiveView, hasSomeChanges } from '@cawita/core-front';
import { Subscription } from 'rxjs';

export type UnreadNotificationContext = {
  $implicit: number;
}

@Directive({
  selector: '[cwtUnreadNotification]'
})
export class UnreadNotificationDirective implements OnChanges, OnDestroy {
  private _ctx: UnreadNotificationContext = { $implicit: 0 };
  private _view = new CwtDirectiveView(this.vcRef, this.tRef, this._ctx);
  private _sub: Subscription;

  @Input('cwtUnreadNotification') view: WidgetViewModeInput;

  constructor(
    private vcRef: ViewContainerRef,
    private tRef: TemplateRef<any>,
    @Inject(SimpleNotificationContract.get(Notification, 'customer')) private customerNotif: SimpleNotificationService<Notification>,
    @Inject(SimpleNotificationContract.get(Notification, 'pro')) private proNotif: SimpleNotificationService<Notification>,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (hasSomeChanges(changes, ['view'], true)) {
      if (this.view === WidgetViewMode.Pro) this._listenTo(this.proNotif);
      else this._listenTo(this.customerNotif);
    }
  }

  ngOnDestroy(): void {
    this._clear();
  }

  private _listenTo(store: SimpleNotificationService<unknown>) {
    this._clear();
    this._sub = store.state$.subscribe(state => {
      this._ctx.$implicit = state.unread ?? 0;
      this._view.ensureState(state.initialized && (state.unread ?? 0) > 0);
    });
  }

  private _clear() {
    this._sub?.unsubscribe();
    this._sub = null;
  }
}
