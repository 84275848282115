import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { getId } from '@cawita/core-front';
import { Notification } from '@shared/models';

@Injectable({ providedIn: 'root' })
export class NotificationHandlerService {
    private _handlers = {
        proValidated: this._goToProSetup.bind(this),
        proRefused: this._goToProSetup.bind(this),
        proNeedChange: this._goToProSetup.bind(this),
        directIntervention: this._goToIntervention.bind(this),
        quoteIntervention: this._goToIntervention.bind(this),
        emergencyIntervention: this._goToIntervention.bind(this),
        removeIntervention: null,
        reOpenIntervention: this._goToIntervention.bind(this),
        missionSuspended: null,
        sendNotificationForProMissionAccomplishedByOtherPro: null,
        interventionEmergencyExpired: null,
        interventionSuspended: null,
        missionProposalCreate: this._goToIntervention.bind(this),
        missionProRefused: this._goToIntervention.bind(this),
        missionProposalCanceled: this._goToIntervention.bind(this),
        proFinished: this._goToIntervention.bind(this),
        proRated: this._goToIntervention.bind(this),
        customerRated: this._goToIntervention.bind(this),
        proFinishedNoRating: this._goToIntervention.bind(this),
        customerMissionPendingInHours: this._goToIntervention.bind(this),
        customerMissionPendingInDays: this._goToIntervention.bind(this),
        customerMissionNotRatedInHours: this._goToIntervention.bind(this),
        customerMissionNotRatedInDays: this._goToIntervention.bind(this),
        proMissionNotRatedInDays: this._goToIntervention.bind(this),
        proMissionNotRatedInHours: this._goToIntervention.bind(this),
        missionCustomerAccepted: this._goToIntervention.bind(this),
        missionCustomerRefused: this._goToIntervention.bind(this),
        missionCustomerCanceled: this._goToIntervention.bind(this),
        customerFinishedNoRating: this._goToIntervention.bind(this),
    }

    constructor(private router: Router) { }

    public handle(notification: Notification) {
        const handler = this._handlers[notification.notifType];
        if (!handler) return;
        return handler(notification);
    }

    private _linkHandler(notification: Notification) {
        if (notification?.linkId?.length) {
            this.router.navigate(notification?.linkId);
        }
    }

    private _goToIntervention(notification: Notification) {
        const interventionId = getId(notification?.mission?.intervention) ?? getId(notification?.intervention);
        if (notification?.pro && interventionId) this.router.navigate(['/pro', 'intervention', getId(interventionId)]);
        else if (notification?.customer && interventionId) this.router.navigate(['/intervention', getId(interventionId)]);
    }

    private _goToProSetup(notification: Notification) {
        this.router.navigate(['/pro', 'setup']);
    }
}